












































































































import { defineComponent, PropType } from "@vue/composition-api";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import {
  IERCProtocolsCardElement,
  ICommissionMemberList,
} from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/types/ERCProtocolsCardElement";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { mapActions, mapGetters } from "vuex";
import ApprovalDecisionForm, { IData as IApprovalDecisionFormApplyObject } from "./ApprovalDecisionForm.vue";
import FormComissionBlock from "./FormComissionBlock.vue";
import FormCommisionsComposition from "./FormCommisionsComposition.vue";
import ResultBlock from "./ResultBlock.vue";

export default defineComponent({
  name: "ComissionWrapper",
  components: {
    ApprovalDecisionForm,
    FormComissionBlock,
    ResultBlock,
    FormCommisionsComposition,
    ApproveTable: () =>
      import(
        /* webpackChunkName: "ERCProtocolsViewApproveTable" */
        "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/components/ApproveTable.vue"
      ),
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    element: {
      type: Object as PropType<Partial<IERCProtocolsCardElement>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      cardMode: CardMode,
      section: Sections.INVENTORY_PROJECT,
      openedPanels: [],
      isPendingApproveFormSubmit: false,
      isPendingApproveCommissionList: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["cardModeList", "user"]),
    commissionMemberList(): ICommissionMemberList[] {
      return this.element?.expertCommissionDto?.commissionMemberList || [];
    },
    userId(): number {
      return this.commissionMemberList?.find((item) => item.username === this.user.name)?.personId || 0;
    },
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isShowFormComission(): boolean {
      return isAuthorityExist(this.user, authorities.EXPERT_COMMISSION_CREATION) && !this.element.expertCommissionDto;
    },
    isShowComissionSelectedUsers(): boolean {
      return !!this.element.expertCommissionDto && !this.isShowApproveForm;
    },
    comissionStatus(): string {
      return this.element.expertCommissionDto?.commissionStatus?.code ?? "NEW";
    },
    isShowCommissionsResults(): boolean {
      return (
        isAuthorityExist(this.user, authorities.EXPERT_COMMISSION_COORDINATOR) &&
        !["APPROVED", "APPROVED_WITH_COMMENTS"].includes(this.comissionStatus) &&
        !!this.commissionMemberList?.find((item) => item.memberKind?.code === "COORDINATING" && item.username === this.user.name)
      );
    },
    isShowApproveForm(): boolean {
      return (
        isAuthorityExist(this.user, authorities.EXPERT_COMMISSION_APPROVER) &&
        !!this.commissionMemberList?.find((item) => item.memberKind?.code === "APPROVING" && item.username === this.user.name)
      );
    },
  },
  methods: {
    ...mapActions("ExpertComissionView", [
      "applyCoordinatingForm",
      "applyApprovingForm",
      "approveCommissionList",
      "blockComission",
      "deleteCommissionFile",
    ]),
    formApprovedState({ isApprove, comment }: { isApprove: boolean; comment: string }) {
      if (!isApprove) {
        return "NOT_APPROVED";
      }

      return comment ? "APPROVED_WITH_COMMENTS" : "APPROVED";
    },
    formApplyFormData(value: IApprovalDecisionFormApplyObject) {
      const formData = new FormData();
      (value.files || []).forEach((file) => {
        formData.append("files", file);
      });

      formData.append(
        "approveDto",
        JSON.stringify({
          comment: value.comment || "",
          approvedState: this.formApprovedState(value),
          expertCommissionId: this.element?.expertCommissionDto?.id,
          personId: this.userId,
        })
      );
      return formData;
    },
    async handleApplyApprovingForm(value: IApprovalDecisionFormApplyObject) {
      try {
        this.isPendingApproveFormSubmit = true;
        const formData = this.formApplyFormData(value);
        const isSaved = await this.applyApprovingForm({ payload: formData });
        if (isSaved) {
          showNotification("Успешно утверждено.", NOTIFICATION_STATUS.SUCCESS);
          this.$emit("onCreateCommision");
        }
      } catch (e) {
        showNotification("Ошибка при отправке утверждения.");
        console.error(e);
      } finally {
        this.isPendingApproveFormSubmit = false;
      }
    },
    async handleApproveCommissionList(object: {
      approveApprovingList: { id: number; fullName: string; status: string }[];
      approveCoordinatingList: { id: number; fullName: string; status: string }[];
    }) {
      try {
        const item: {
          inventoryProjectId: number;
          commissionMemberList: {
            personId: number;
            memberKind: string;
          }[];
        } = {
          inventoryProjectId: this.element?.id || 0,
          commissionMemberList: [
            ...object.approveCoordinatingList.map((item) => ({ personId: item.id, memberKind: "COORDINATING" })),
            ...object.approveApprovingList.map((item) => ({ personId: item.id, memberKind: "APPROVING" })),
          ],
        };
        this.isPendingApproveCommissionList = true;
        const isSaved = await this.approveCommissionList(item);

        if (isSaved) {
          showNotification("Комиссия успешно создана.", NOTIFICATION_STATUS.SUCCESS);
          this.$emit("onCreateCommision");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isPendingApproveCommissionList = false;
      }
    },
    applyBlockComission() {
      const peopleWithoutStatus =
        this.element.expertCommissionDto?.commissionMemberList?.filter(
          (item: ICommissionMemberList) => item.memberKind?.code === "COORDINATING" && item.approvedState?.code === "IN_PROGRESS"
        ) || [];
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "WarningModal", {
          cbOnDelete: () => {
            this.handleBlockComission();
          },
          title: peopleWithoutStatus.length
            ? "Не все согласования получены от согласующих. Вы уверены, что нужно окончательно утвердить протокол?"
            : "Вы уверены, что хотите утвердить результаты согласования?",
        })
      );
    },
    async handleBlockComission() {
      const isSaved = await this.blockComission({
        expertCommissionId: this.element?.expertCommissionDto?.id,
        personId: this.userId,
      });

      if (isSaved) {
        showNotification("Успешно утверждено.", NOTIFICATION_STATUS.SUCCESS);
        this.$emit("onCreateCommision");
      }
    },
    async handleApplyCoordinatingForm(value: IApprovalDecisionFormApplyObject) {
      try {
        this.isPendingApproveFormSubmit = true;
        const formData = this.formApplyFormData(value);
        const deletedFiles = (value.filesOld || []).filter((file) => !!file.isDeleted);

        await Promise.all(
          deletedFiles.map((file) => {
            return this.deleteCommissionFile(file);
          })
        );

        const isSaved = await this.applyCoordinatingForm({
          payload: formData,
        });

        if (isSaved) {
          showNotification("Успешно утверждено.", NOTIFICATION_STATUS.SUCCESS);
          this.$emit("onCreateCommision");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isPendingApproveFormSubmit = false;
      }
    },
  },
});

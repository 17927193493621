





































































import { defineComponent, PropType } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { mapGetters, mapActions } from "vuex";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { ICommissionMemberList } from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/types/ERCProtocolsCardElement";
import AddedFile from "./AddedFile.vue";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import TableFileSignature from "./TableFileSignature.vue";

export interface IData {
  isApprove: boolean;
  comment: string;
  files: File[];
  filesOld: {
    id: number;
    name: string;
    type: string;
    isDeleted?: boolean;
    uuid: string;
    signatureGuid?: string;
    fileType: string;
  }[];
  tooltipZIndex: number;
}

export default defineComponent({
  name: "ApprovalDecisionForm",
  components: {
    AddedFile,
    TableFileSignature,
  },
  props: {
    agreedButtonText: {
      type: String,
      default() {
        return "Утвердить";
      },
    },
    notAgreedButtonText: {
      type: String,
      default() {
        return "Не утвердить";
      },
    },
    isApproveModal: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isSumbitLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    buttonText: {
      type: String,
      default() {
        return "Сохранить";
      },
    },
    isHideSaveBtn: {
      type: Boolean,
      default() {
        return false;
      },
    },
    commissionMemberList: {
      type: Array as PropType<ICommissionMemberList[]>,
      default() {
        return [];
      },
    },
  },
  data(): IData {
    return {
      isApprove: false,
      comment: "",
      filesOld: [],
      files: [] as File[],
      tooltipZIndex: 200,
    };
  },
  computed: {
    ...mapGetters("auth", ["cardModeList", "user"]),
    isCanSave(): boolean {
      return true;
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    async addFile(file: File) {
      this.files = this.files.concat(file);
    },
    openFile(item: { id: number; name: string; type: string; uuid: string }) {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_RECEIPT_DOWNLOAD)}?uuid=${item.uuid}&origin=true`);
    },
    deleteFile(index: number) {
      this.files = this.files.filter((item, i) => index !== i);
    },
    deleteOldFile(item: { id: number; name: string; type: string; uuid: string }) {
      this.filesOld = this.filesOld.map((file: { id: number; name: string; type: string; isDeleted?: boolean; uuid: string; fileType: string }) => {
        return {
          ...file,
          isDeleted: item.id === file.id ? !file.isDeleted : file.isDeleted,
        };
      });
    },
    applyForm() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "WarningModal", {
          cbOnDelete: () => {
            this.$emit("applyForm", { isApprove: this.isApprove, comment: this.comment, files: this.files, filesOld: this.filesOld });
          },
          title: "Вы уверены, что хотите отправить решение о согласовании?",
        })
      );
    },
  },
  watch: {
    commissionMemberList: {
      immediate: true,
      handler(value: ICommissionMemberList[]) {
        const item = value?.find(
          (item) => item.username === this.user.name && item.memberKind?.code === (this.isApproveModal ? "APPROVING" : "COORDINATING")
        );
        if (!item) {
          return;
        }
        this.isApprove = item.approvedState?.code !== "NOT_APPROVED" || false;
        this.comment = item.comment || "";
        this.files = [];
        this.filesOld =
          item.fileList.map((file) => {
            return {
              name: file.filename,
              isDeleted: false,
              type: file.filename.replaceAll(".", "/"),
              uuid: file.uuid,
              id: file.id,
              signatureGuid: file.signatureGuid,
              fileType: file.type,
            };
          }) || [];
      },
    },
  },
});

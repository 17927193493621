














import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AddedFile",
  props: {
    file: {
      type: File || Object,
    },
    isHideBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fileName(): string {
      return (this.file as { name: string })?.name || "";
    },
    type(): string {
      return ((this.file as { type: string })?.type || "").split("/").pop()?.slice(0, 3) || "-";
    },
  },
});

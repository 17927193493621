export const signatureFields = [
  {
    value: "commonName",
    title: "Кому выдан",
    tooltip: "Фамилия имя и отчество владельца сертификата",
    className: "signature__element_title",
  },
  {
    value: "organizationName",
    title: "Кем выдан",
    tooltip: "Наименование юридического лица АУЦ, выдавшего сертификат",
    className: "signature__element_title",
  },
  {
    value: "serNum",
    title: "Серийный номер",
    tooltip: "Серийный номер сертификата",
    className: "signature__element_title",
  },
  {
    value: "expiredAt",
    title: "Действителен",
    tooltip: "Дата окончания действия сертификата",
    className: "signature__element",
  },
  {
    value: "signTime",
    title: "Дата подписания",
    tooltip: "Дата подписания файла электронной подписью",
    className: "signature__element",
  },
  {
    value: "timeStamp",
    title: "Штамп времени",
    tooltip: "Штамп времени",
    className: "signature__element_title",
  },
  {
    value: "tsSerNum",
    title: "Серийный номер сервера штампа времени",
    tooltip: "Серийный номер сервера штампа времени",
    className: "signature__element_title",
  },
];























































































import { defineComponent } from "@vue/composition-api";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { mapGetters } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

const tableHeaders = [
  { defaultWidth: 400, value: "fullName", text: "Фамилия Имя Отчество", tooltip: "ФИО" },
  { defaultWidth: 79, value: "additionalSlot", text: "", tooltip: "" },
];

export default defineComponent({
  name: "ComissionWrapper",
  components: {
    ApproveTable: () =>
      import(
        /* webpackChunkName: "ERCProtocolsViewApproveTable" */
        "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/components/ApproveTable.vue"
      ),
  },
  props: {
    isLoadingApproveCommission: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    element: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tableHeaders,
      cardMode: CardMode,
      section: Sections.INVENTORY_PROJECT,
      approveApprovingList: [] as { id: number; fullName: string; status: string }[],
      approveCoordinatingList: [] as { id: number; fullName: string; status: string }[],
    };
  },
  computed: {
    ...mapGetters("auth", ["cardModeList"]),
    isCanSave(): boolean {
      return !!(this.approveApprovingList?.length && this.approveCoordinatingList?.length);
    },
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
  },
  methods: {
    handleApproveCommission() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "WarningModal", {
          cbOnDelete: () => {
            this.$emit("approveCommission", {
              approveApprovingList: this.approveApprovingList,
              approveCoordinatingList: this.approveCoordinatingList,
            });
          },
          title: "Вы уверены, что хотите сформировать состав ЭПК?",
        })
      );
    },
    handleDeleteUser(type: string, user: { id: number; fullName: string; status: string }) {
      if (type === "APPROVING") {
        this.approveApprovingList = this.approveApprovingList.filter((item) => item.id !== user.id);
      } else {
        this.approveCoordinatingList = this.approveCoordinatingList.filter((item) => item.id !== user.id);
      }
    },
    selectApprovingUsers(users: { id: number; fio: string }[]) {
      this.approveApprovingList = users.map((item) => {
        return {
          ...item,
          fullName: item.fio,
          status: "APPROVED",
        };
      });
    },
    selectCoordinatingUsers(users: { id: number; fio: string }[]) {
      this.approveCoordinatingList = users.map((item) => {
        return {
          ...item,
          fullName: item.fio,
          status: "APPROVED",
        };
      });
    },
    addUsers(type: string) {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "CommisionUserModal", {
          type,
          listUsers: type === "APPROVING" ? this.approveApprovingList : this.approveCoordinatingList,
          ...(type === "APPROVING" ? { title: "Добавление утверждающего" } : { title: "Добавление согласующих" }),
          ...(type === "APPROVING"
            ? { subTitle: "Вы можете добавить утверждающего из списка" }
            : { subTitle: "Вы можете добавить несколько согласующих из списка" }),
          ...(type === "APPROVING" ? { maxUserLength: 1 } : {}),
          approveModal: type === "APPROVING" ? this.selectApprovingUsers : this.selectCoordinatingUsers,
        })
      );
    },
  },
});

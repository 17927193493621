






























































































import { defineComponent } from "@vue/composition-api";
import TreeStorage from "@monorepo/inventory/src/components/TreeStorage.vue";
import SelectRolesCard from "@monorepo/uikit/src/components/common/Select/SelectRolesCard.vue";
import { mapActions, mapGetters } from "vuex";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";

export default defineComponent({
  components: {
    TreeStorage,
    SelectRolesCard,
    FormInputElement,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    caseIds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      treeOpen: [],
      status: null as Record<string, string> | null,
      currentNodeId: "",
      formValues: {
        statusComment: "",
        date: "",
        // changeReason: "",
      },
      isSaveLoading: false,
    };
  },
  computed: {
    ...mapGetters("ERCProtocolsView", ["paperStatuses"]),
    isDisabledChangeBtn(): boolean {
      return !this.caseIds.length;
    },
    isConfirmedStatus(): boolean {
      return this.status?.id === "RECEIPT_CONFIRMED";
    },
    isRequiredComments(): boolean {
      return !!this.status?.id && ["WITHDRAWN_OR_LOST", "DE_REGISTERED"].includes(this.status.id);
    },
    isRequiredDate(): boolean {
      return !!this.status?.id && ["RECEIPT_CONFIRMED", "UNDER_REVIEW"].includes(this.status.id);
    },
    isDisabledSave(): boolean {
      return (
        !this.status ||
        (this.isConfirmedStatus && !this.currentNodeId) ||
        (this.isRequiredDate && !this.formValues.date) ||
        (this.isRequiredComments && !this.formValues.statusComment)
      );
    },
    paperSelectStatuses(): { label: string; id: string }[] {
      return this.paperStatuses.map((item: any) => ({
        label: item.title,
        id: item.code,
      }));
    },
  },
  methods: {
    ...mapActions("ERCProtocolsView", ["savePaperStatus"]),
    closeModal() {
      this.dialogVisible = false;
    },
    async saveSelection() {
      this.isSaveLoading = true;
      const payload = {
        status: this.status?.id,
        caseIds: this.caseIds,
        addressNodeId: this.isConfirmedStatus ? this.currentNodeId : null,
        ...(this.isRequiredComments
          ? {
              statusComment: this.formValues.statusComment,
              // commentChangePlacementAddress: this.formValues.changeReason,
            }
          : {
              statusComment: "",
            }),
        ...(this.isRequiredDate
          ? {
              dateTransferToStorage: this.formValues.date,
            }
          : {}),
      };
      const isSaved = await this.savePaperStatus(payload);
      this.isSaveLoading = false;
      if (isSaved) {
        showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
        this.$emit("refresh");
        this.closeModal();
      }
    },
    showModal() {
      this.currentNodeId = "";
      this.formValues.date = "";
      this.formValues.statusComment = "";
      // this.formValues.changeReason = "";
      this.status = null;
      this.dialogVisible = true;
    },
    changeTree({ id }: { id: string }) {
      this.currentNodeId = id;
    },
  },
});

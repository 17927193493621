import { render, staticRenderFns } from "./AddedFile.vue?vue&type=template&id=30be428a&scoped=true&"
import script from "./AddedFile.vue?vue&type=script&lang=ts&"
export * from "./AddedFile.vue?vue&type=script&lang=ts&"
import style0 from "./AddedFile.vue?vue&type=style&index=0&id=30be428a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30be428a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'd-flex flex-row align-start flex-wrap form-comission__wrap': _vm.cardModeResult === _vm.cardMode.THREE_QUARTHER || _vm.cardModeResult === _vm.cardMode.FULL,
  }},[_c('div',{class:[
      'info-modal__table-full-width',
      {
        'info-modal__table-half-width': _vm.cardModeResult === _vm.cardMode.THREE_QUARTHER || _vm.cardModeResult === _vm.cardMode.FULL,
      } ]},[(_vm.isLoading)?_c('TmpBlock',{staticClass:"mb-2",attrs:{"height":"24"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row mb-2 align-center"},[_c('span',{staticClass:"info-modal__sub-title-sub"},[_vm._v("Согласующие")]),_c('v-spacer'),_c('v-btn',{staticClass:"info-modal__download-btn",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.addUsers('COORDINATING')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#2462D1"}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Добавить согласующего")])],1)],1),_c('keep-alive',[(_vm.isLoading)?_c('div',_vm._l((5),function(item){return _c('TmpBlock',{key:item,staticClass:"mt-1 mb-1",attrs:{"height":"24"}})}),1):_vm._e(),_c('ApproveTable',{staticClass:"mb-6",attrs:{"tableHeaders":_vm.tableHeaders,"element":_vm.element,"items":_vm.approveCoordinatingList},on:{"edit":function($event){return _vm.addUsers('COORDINATING')},"delete":function($event){return _vm.handleDeleteUser('COORDINATING', $event)}}})],1)],1),_c('div',{class:[
      'info-modal__table-full-width',
      {
        'info-modal__table-half-width': _vm.cardModeResult === _vm.cardMode.THREE_QUARTHER || _vm.cardModeResult === _vm.cardMode.FULL,
      } ]},[(_vm.isLoading)?_c('TmpBlock',{staticClass:"mb-2",attrs:{"height":"24"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row mb-2 align-center"},[_c('span',{staticClass:"info-modal__sub-title-sub"},[_vm._v("Утверждающий")]),_c('v-spacer'),_c('v-btn',{staticClass:"info-modal__download-btn",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.addUsers('APPROVING')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#2462D1"}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Добавить утверждающего")])],1)],1),_c('keep-alive',[(_vm.isLoading)?_c('div',[_c('TmpBlock',{staticClass:"mt-1 mb-1",attrs:{"height":"24"}})],1):_vm._e(),_c('ApproveTable',{staticClass:"mb-6",attrs:{"tableHeaders":_vm.tableHeaders,"element":_vm.element,"items":_vm.approveApprovingList,"isMainPerson":true},on:{"edit":function($event){return _vm.addUsers('APPROVING')},"delete":function($event){return _vm.handleDeleteUser('APPROVING', $event)}}})],1)],1),_c('div',{staticClass:"mt-4 form-comission__btn-wrap"},[_c('v-btn',{staticClass:"info-modal__download-btn",attrs:{"width":"252","height":"40","color":"primary","loading":_vm.isLoadingApproveCommission,"disabled":!_vm.isCanSave},on:{"click":_vm.handleApproveCommission}},[_c('span',[_vm._v("Утвердить состав комиссии")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'd-flex flex-row align-start': _vm.cardModeResult === _vm.cardMode.THREE_QUARTHER || _vm.cardModeResult === _vm.cardMode.FULL,
  }},[_c('div',{class:[
      'info-modal__table-full-width',
      {
        'info-modal__table-half-width mr-2': _vm.cardModeResult === _vm.cardMode.THREE_QUARTHER || _vm.cardModeResult === _vm.cardMode.FULL,
      } ]},[(_vm.isLoading)?_c('TmpBlock',{staticClass:"mb-2",attrs:{"height":"24"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row mb-2 align-center"},[_c('span',{staticClass:"info-modal__sub-title-sub"},[_vm._v("Согласующие")]),_c('v-spacer')],1),_c('keep-alive',[(_vm.isLoading)?_c('div',_vm._l((5),function(item){return _c('TmpBlock',{key:item,staticClass:"mt-1 mb-1",attrs:{"height":"24"}})}),1):_vm._e(),_c('ApproveTable',{staticClass:"mb-6",attrs:{"tableHeaders":_vm.tableHeaders,"element":_vm.element,"items":_vm.coordinatingList,"isHideEditButtons":""}})],1)],1),_c('div',{class:[
      'info-modal__table-full-width',
      {
        'info-modal__table-half-width ml-2': _vm.cardModeResult === _vm.cardMode.THREE_QUARTHER || _vm.cardModeResult === _vm.cardMode.FULL,
      } ]},[(_vm.isLoading)?_c('TmpBlock',{staticClass:"mb-2",attrs:{"height":"24"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row mb-2 align-center"},[_c('span',{staticClass:"info-modal__sub-title-sub"},[_vm._v("Утверждающий")]),_c('v-spacer')],1),_c('keep-alive',[(_vm.isLoading)?_c('div',[_c('TmpBlock',{staticClass:"mt-1 mb-1",attrs:{"height":"24"}})],1):_vm._e(),_c('ApproveTable',{staticClass:"mb-6",attrs:{"tableHeaders":_vm.tableHeaders,"element":_vm.element,"items":_vm.approveList,"isMainPerson":true,"isHideEditButtons":""}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }









import { defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";
import moment from "moment";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import { signatureFields } from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/constants/signatureFields";

export default defineComponent({
  name: "TableFileSignature",
  components: {
    CardModalInfoElement,
  },
  props: {
    signGuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      signatureFields,
      signData: {} as Record<string, any>,
      isLoading: false,
    };
  },
  computed: {
    formSerialNumber(): (text: string) => string {
      return (text: string) => {
        if (!text) {
          return "-";
        }
        return text?.match(/.{1,2}/g)?.join(" ") || "";
      };
    },
    formDate(): (text: string) => string {
      return (text: string) => {
        if (!text) {
          return "-";
        }
        return moment(text).format("YYYY-MM-DD HH:mm");
      };
    },
    resultData(): Record<string, string> {
      return {
        commonName: this.signData?.commonName || "-",
        organizationName: this.signData?.organizationName || "-",
        serNum: this.formSerialNumber(this.signData?.serNum),
        expiredAt: this.formDate(this.signData?.expiredAt),
        signTime: this.formDate(this.signData?.signTime),
        timeStamp: this.formDate(this.signData?.timeStamp),
        tsSerNum: this.formSerialNumber(this.signData?.tsSerNum),
      };
    },
  },
  methods: {
    ...mapActions("ExpertComissionView", ["getSignatures"]),
  },
  async mounted() {
    if (this.signGuid) {
      this.isLoading = true;
      this.signData = await this.getSignatures(this.signGuid);
      this.isLoading = false;
    }
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4 d-flex"},[_c('div',{class:[
        'approval-decision-form__approve-btn approval-decision-form__approve-btn-left',
        { 'approval-decision-form__approve-btn_active': _vm.isApprove },
        { 'approval-decision-form__approve-btn_disabled': _vm.isHideSaveBtn } ],on:{"click":function($event){_vm.isApprove = true}}},[_vm._v(" "+_vm._s(_vm.agreedButtonText)+" ")]),_c('div',{class:[
        'approval-decision-form__approve-btn approval-decision-form__approve-btn-right',
        { 'approval-decision-form__approve-btn_active': !_vm.isApprove },
        { 'approval-decision-form__approve-btn_disabled': _vm.isHideSaveBtn } ],on:{"click":function($event){_vm.isApprove = false}}},[_vm._v(" "+_vm._s(_vm.notAgreedButtonText)+" ")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],staticClass:"approval-decision-form__textarea",attrs:{"disabled":_vm.isHideSaveBtn,"placeholder":"Оставьте ваш комментарий"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comment=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"mt-3 d-flex align-center"},[_vm._l((_vm.filesOld),function(item){return _c('div',{key:item.id,staticClass:"mr-6"},[(item.fileType === 'SIGN')?_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","fixed":"","z-index":_vm.tooltipZIndex,"top":"","close-delay":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddedFile',{attrs:{"isHideBtn":_vm.isHideSaveBtn,"file":item},on:{"openFile":function($event){return _vm.openFile(item)},"delete":function($event){return _vm.deleteOldFile(item)}}})],1)]}}],null,true)},[_c('div',{staticClass:"pa-4 signature"},[_c('TableFileSignature',{attrs:{"signGuid":item.signatureGuid}})],1)]):_c('AddedFile',{attrs:{"isHideBtn":_vm.isHideSaveBtn,"file":item},on:{"openFile":function($event){return _vm.openFile(item)},"delete":function($event){return _vm.deleteOldFile(item)}}})],1)}),_vm._l((_vm.files),function(item,index){return _c('div',{key:item.name,staticClass:"mr-6"},[_c('AddedFile',{attrs:{"isHideBtn":_vm.isHideSaveBtn,"file":item},on:{"delete":function($event){return _vm.deleteFile(index)}}})],1)}),_c('div',{staticClass:"d-flex ml-2 approval-decision-form__file-append"},[_c('v-file-input',{staticClass:"d-inline-block pa-0 mt-0",attrs:{"hide-input":"","disabled":_vm.files.length + _vm.filesOld.length > 4 || _vm.isHideSaveBtn},on:{"change":function($event){return _vm.addFile($event)}}}),_vm._m(0)],1)],2),(!_vm.isHideSaveBtn)?_c('v-btn',{staticClass:"mt-6 approval-decision-form__btn",attrs:{"disabled":!_vm.isCanSave,"loading":_vm.isSumbitLoading,"width":"204","height":"40","color":"primary"},on:{"click":_vm.applyForm}},[_vm._v(_vm._s(_vm.buttonText))]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-3 approval-decision-form__file-append-text-wrapper"},[_c('div',[_vm._v("Прикрепить файлы")]),_c('div',{staticClass:"approval-decision-form__file-append-text-wrapper_grey"},[_vm._v("Не более 5 файлов")])])}]

export { render, staticRenderFns }